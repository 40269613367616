<template>
    <div>
      <v-card>
        <v-toolbar flat color="white">
          <v-toolbar-title>Buscar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>
          <v-spacer></v-spacer>
  
          <v-text-field
            v-model="searchCodCliente"
            append-icon="mdi-magnify"
            label="Codigo cliente"
            single-line
            class="mt-3 mx-4"
            @keydown.enter="cargarLista(true)"
            @click:append="cargarLista(true)"
          ></v-text-field>
  
          <v-text-field
            v-model="searchCliente"
            append-icon="mdi-magnify"
            label="Cliente"
            single-line
            class="mt-3 mx-4"
            @keydown.enter="cargarLista(true)"
            @click:append="cargarLista(true)"
          ></v-text-field>
        </v-toolbar>
  
        <v-toolbar flat color="white">
          <v-btn
            color="primary"
            small
            class="ma-2 white--text"
            @click="exportar()"
          >
            <v-icon left>mdi-microsoft-excel</v-icon>
            Exportar
          </v-btn>
        </v-toolbar>
  
        <v-row>
          <v-col md="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="lista"
              :items-per-page="1000"
              :loading="loadingTable"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.opcion="{ item }">
                <v-btn
                  v-if="item.invoice_id != null"
                  icon
                  target="_blank"
                  :to="`/factura-venta/${item.invoice_id}`"
                >
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  v-if="item.invoice_id == null"
                  icon
                  target="_blank"
                  :to="`/ver-pago/${item.payment_id}`"
                >
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
  
              <template slot="body.append">
                <tr class="pink--text">
                  <th class="title">Total</th>
                  <th class="title"></th>
                  <th class="title"></th>
                  <th class="title"></th>
                  <th class="title"></th>
                  <th class="title"></th>
                  <th class="title">{{ suma("total") }}</th>
                  <th class="title">{{ suma("retendio") }}</th>
                  <th class="title">{{ suma("pagado") }}</th>
                  <th class="title" align="right">{{ suma("saldo") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  
  export default {
    name: "AsientosContablesLista",
  
    data: () => ({
      headers: [
        { text: "Opciones", value: "opcion", align: "center", width: 40 },
        { text: "Numero", value: "numero_doc" },
        { text: "Fecha", value: "invoice_date" },        
        { text: "Tipo", value: "tipo" },
        { text: "Cliente", value: "cliente" },
        { text: "Total", value: "total", align: "right" },
        
      ],
      lista: [],
      options: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: true,
  
      
  
      currentPage: 1,
      pageCount: 1,
      itemsPerPage: 50,
  
      searchNumber: "",
      searchFechaInv: "",
      searchEstado: "",
      searchCliente: "",
      searchCodCliente: "",
    }),
    computed: {
      ...mapState("master", ["loadingTable", "user", "tenantId"]),
  
      ...mapGetters("access", ["btnAbrirCaja"]),
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setOverlay",
        "setMenu",
        "setTitleToolbar",
        "setLoadingTable",
      ]),
  
      ...mapActions("master", ["requestApi"]),
  
      cargarLista(filtrar) {
        if (filtrar == true) {
          this.currentPage = 1;
        }
        this.setLoadingTable(true);
  
        this.setUrl("cuentas-por-cobrar");
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,
            page_count: this.pageCount,
            page_size: this.itemsPerPage,
            cliente: this.searchCliente,
            invoiceNumber: this.searchNumber,
            statusId: this.searchEstado,
            invoiceDate: this.searchFechaInv,
            party_id: this.searchCodCliente,
          },
        })
          .then((res) => {
            //console.log(res.data.lista);
            this.lista = res.data._embedded.cuentas_por_cobrar;
            //this.pageCount = res.data.page_count;
            this.setLoadingTable(false);
          })
          .then(() => {});
      },
  
      exportar() {
        this.setUrl("cuentas-por-cobrar");
        this.requestApi({
          method: "POST",
          data: {
            cliente: this.searchCliente,
          },
        })
          .then((res) => {
            //console.log(res.data);
  
            var a = document.createElement("a");
            a.href =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              res.data.datos;
            a.download = res.data.archivo;
            a.click();
          })
          .then(() => {});
      },
      suma(col) {
        return this.lista
          .reduce(function(a, b) {
            if (b["signo"] == "pos") {
              return a + (b[col] || 0);
            } else {
              return a + (b[col] * -1 || 0);
            }
          }, 0)
          .toFixed(2);
      },
    },
  
    mounted() {
      this.setTitleToolbar("ASIENTOS CONTABLES");
      this.cargarLista(false);
    },
  
    watch: {
      searchNumber: {
        handler() {
          this.cargarLista(true);
        },
      },
      searchEstado: {
        handler() {
          this.cargarLista(true);
        },
      },
    },
  };
  </script>
  